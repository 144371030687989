import styled from '@emotion/styled';
import { breakpoints, colors, dimensions } from '../../styles/variables';
import { Visibility } from "../../types";

const TOP_BACKGROUND_HEIGHT = "1.45vw";
const BOTTOM_BACKGROUND_HEIGHT = "1.88vw";


export const SafariWrapper = styled.div`
  width: 100%;
  overflow: hidden;

`;

export const Wrapper = styled.section<Visibility>`
  padding-bottom: ${BOTTOM_BACKGROUND_HEIGHT};
  margin-top: ${TOP_BACKGROUND_HEIGHT};
  // margin-bottom: calc(95px + ${BOTTOM_BACKGROUND_HEIGHT});
  // padding: 0 ${dimensions.containerPadding}px;
  position: relative;
  z-index: 2;
  
  @media screen and (max-width: ${breakpoints.lg}px) {
    height: auto;
    margin-bottom: calc(60px + ${BOTTOM_BACKGROUND_HEIGHT});
    margin-top:30px;
    .left .content{flex-direction:column;}
    

  }
  @media screen and (max-width: ${breakpoints.sm}px) {
    margin-bottom:35px;
  }

  &:after {
    content: "";
    width: 100%;
    position: absolute;
    left: 0;
    background-size: contain;
    height: 180px;
    bottom: -130px;
    background: url('/salespage/4th section/bottom-beige-path-coverup.svg') no-repeat center 0;
    background-size: 100% 100px;

    @media screen and (min-width: 2300px) {
      background-size: 100% 100%;
    }
    
    @media screen and (max-width: ${breakpoints.lg}px) {
      transform: scale(1.1);
      height: 85px; bottom:-100px;
    }
    @media screen and (max-width: ${breakpoints.sm}px) {
      bottom:-65px;
    }
  }




`;

export const Heading = styled.header`
  width: 100%;
  margin-bottom: 33px;
  text-align: center;
`;

export const HeadingTitle = styled.h2`
  margin: 0;
  color: ${colors.darkNavyBlue};
  font-size: ${dimensions.fontSize.heading}px;
  font-weight: 900;
  line-height: ${dimensions.lineHeight.heading};

  @media screen and (max-width: ${breakpoints.lg}px) {
    font-size: ${dimensions.fontSize.heading * 0.7}px;
  }
`;

export const SubTitle = styled.h3`

  /*color: #9d978b;
  font-weight: 900;*/
  font-size: 30px;
  line-height: ${dimensions.lineHeight.large};
  //position: absolute;
  text-align: center;
  width: 45%;
  z-index: 2;
  @media screen and (max-width: ${breakpoints.lg}px) {
    position:relative;
    width:100%;
    text-align:left;
    margin-bottom:50px;
    margin-top:0;
  }
  @media screen and (max-width: ${breakpoints.sm}px) {
    font-size: 22px;
  }
`;

export const CheckList = styled.span`
  &:before {
    content: url("/salespage/2nd section/bullet-green-check.svg"); 
    margin-right: 20px
  }
  
  font-size: 15px;
`;

export const LeftColumn = styled.div`
  padding-right: 20px;
 
  @media screen and (max-width: ${breakpoints.lg}px) {
    margin-left: 0px;
    padding-right:0;
    text-align:center;
  }
`;

export const Content = styled.div`
  color: ${colors.lightGray};
  font-size: ${dimensions.fontSize.medium}px;
  line-height: 1.63;

  p {
    margin: 26px 0;
    text-align: left;
  }

  @media screen and (max-width: ${breakpoints.xl}px) {
    font-size: ${dimensions.fontSize.medium * 0.9}px;
  }
  @media screen and (max-width: ${breakpoints.lg}px) {
    align:items:center;
  }
  @media screen and (max-width: ${breakpoints.md}px) {
     flex-direction:column !important;
  }

`;

export const RightColumn = styled.figure`
@media screen and (max-width: ${breakpoints.xl}px) {
margin-right:0;
}
  @media screen and (max-width: ${breakpoints.lg}px) {
    margin: 0;
    padding: 0;
  }
`;

export const Illustration = styled.img`
  transform: translate(21px, -12px);
  margin-left: -200px;
  margin-top: -20px;
  width: 700px;
  @media screen and (max-width: 1340px) {
    margin-left: -140px;
  }
  @media screen and (min-width: 1251px) and (max-width: 1300px) {
    width: 650px;margin-left: -120px;
  }
  @media screen and (min-width: 1201px) and (max-width: 1250px) {
    width: 600px;margin-left: -80px;
  }
  @media screen and (max-width: ${breakpoints.xl}px) {
    // max-width: 80%;
    // transform: none;
    // margin-left: 60px;
    // margin-top: 60px;
    width: 600px;margin-left: -70px;

  }
  @media screen and (max-width: ${breakpoints.lg}px) {
    max-width: 90%;
    transform: none;
    margin-left: 0px;
    margin-top: 0px;
    width:100%;

  }
`;

export const FeatureParagraphMarkLong = styled.span`
  position: relative;

  &:before {
    content: '';
    width: 180px;
    height: 12px;
    position: absolute;
    bottom: -13px;
    right: -250px;
    z-index: -1;
    background: url('/salespage/4th section/long-blue-underline.svg') no-repeat 0 0;
    background-size: contain;
    @media screen and (max-width: ${breakpoints.sm}px) {
      background-size: 50%;right:-180px;
    }
    // @media screen and (max-width: 414px) {
    //   right: 238px;
    // }

    // @media screen and (max-width: 400px) {
    //   right: 48px;
    // }

    // @media screen and (max-width: 280px) {
    //   right: 108px;
    // }
  }
  // &:after {
  //   content: '';
  //   width: 34px;
  //   height: 17px;
  //   position: absolute;
  //   bottom: -14px;
  //   left: 0;
  //   z-index: -1;
  //   background: url('/salespage/4th section/long-blue-underline.svg') no-repeat 0 0;
  //   background-size: contain;

  //   @media screen and (max-width: 414px) {
  //     left: -290px;
  //   }
  //   @media screen and (max-width: 400px) {
  //     left: 0px;
  //   }
  //   @media screen and (max-width: 280px) {
  //     left: -170px;
  //   }
  // }
`;

export const FeatureParagraphMarkShort = styled.span`
  position: relative;


  &:after {
    content: '';
    width: 34px;
    height: 17px;
    position: absolute;
    bottom: -14px;
    left: 10px;
    z-index: -1;
    background: url('/salespage/4th section/short-blue-underline.svg') no-repeat 0 0;
    background-size: contain;

    @media screen and (max-width: 414px) {
      left: -290px;
    }
    @media screen and (max-width: 400px) {
      left: 0px;
    }
    @media screen and (max-width: 280px) {
      left: -170px;
    }
  }
`;


export const ContainerYellow = styled.div`
  width: 1117px;
  margin: auto;

 
  height: 300px;
 

  border-radius: 10px;
  color:#000;
  padding: 30px ;
  padding-bottom: 0px;
  margin-top: 70px;

  background-color: #FEDA53;
 
  height: 500px;

  @media screen and (max-width: 1100px) {
    width: 100%;
  }

  @media screen and (max-width: ${breakpoints.lg}px) {
    width: 100%;
  }
  @media screen and (max-width: ${breakpoints.lg}px) {
    width:100%;
    height: auto;
    padding:30px 15px;
  }

  @media screen and (max-width: ${breakpoints.sm}px) {
    width:100%;

  }

 
`;


export const Banner = styled.div`
    display: grid;
    grid-template-columns: 1fr 0.9fr;

    background: url('/salespage/1st section/black-frame.png') no-repeat center 0;
    background-size: 100% 98%;


    height: 500px;
    margin-left: -25px;
    margin-top: -25px;
    width: 1107px;

    @media screen and (max-width: 1100px) {
      width: calc(100% + 50px);
    }

    @media screen and (max-width: ${breakpoints.lg}px) {
      margin-top: 0px;
      height: 550px;
    }

    @media screen and (max-width: ${breakpoints.md}px) {
      grid-template-columns: 1fr;
      width:100%;
      margin-left: 0px;
      margin-top: 0px;
      height: 1000px;
      background: none;
    }

    @media screen and (max-width: ${breakpoints.sm}px) {
      width:100%;
      margin-left: 0px;
      margin-top: 0px;
      height: 770px;
      background: none;
    }

    @media screen and (max-width: 540px) {
      height: 800px;
    }

    @media screen and (max-width: 450px) {
      height: 770px;
    }

`;

export const BannerLeftColumn = styled.div`
  margin-left: 60px;
  

  @media screen and (max-width: ${breakpoints.md}px) {
    margin-bottom:30px;
    font-size:18px;
    margin-left: 10px;
    height: 0px;
  }

  font-size: ${dimensions.fontSize.medium}px;

  @media screen and (max-width: ${breakpoints.sm}px) { 
    font-size:18px;
    margin-left: 10px;
    height: 150px;
    margin-top: -20px;
  }

  @media screen and (max-width: 450px) { 
    height: 0px;
  }

  h2{
    float:left;
  }
  p{
    margin-bottom: 25px;

  }
`;

export const BannerRightColumn = styled.div`

  background: url('/salespage/1st section/powered-by-ai.svg') no-repeat center 0;
  background-size: 100%;
  background-position: -25px 10px;

  @media screen and (max-width: ${breakpoints.lg}px) {
    background-position: 0px 100px;
  }

  @media screen and (max-width: ${breakpoints.md}px) {
    background-position: 0px 100px;
    background-size: 98%;
    margin-top: -220px;
  }

  @media screen and (max-width: ${breakpoints.sm}px) {
    background-position: 0px 0px;
    background-size: 98%;
    margin-top: 100px;
  }

  @media screen and (max-width: 540px) {
    margin-top: -100px;
  }

  @media screen and (max-width: 450px) {
    margin-top: 130px;
  }

  @media screen and (max-width: 360px) {
    margin-top: 150px;
  }

  @media screen and (max-width: 280px) {
    margin-top: 310px;
  }
`;


export const Bottom = styled.div`
    width: 1107px;
    margin: auto;
    margin-bottom:125px;
    margin-top: 30px;
    position: relative;

    div{
      margin:auto;
      width: 900px;
      height: 50px;
    }


    @media screen and (max-width: 1100px) {
      width: 100%  ;
      div{
        margin:auto;
        width: 600px;
        height: 50px;
      }
    }

    
    @media screen and (max-width: ${breakpoints.sm}px) {
      width: 100%;
      margin-bottom:188px;
      div{
        width: 100%;

      }
    }
`;

export const AITypes = styled.div`
  width: 200px !important;
  padding-right: 10px;
   margin-left: 20px !important;
   float: left;
   border: 1px solid #ddd;
   border-radius: 5px; 

   p{
    float: left;
    margin-left: 10px;
    margin-top: 16px;
   }

   img{
    float: left;
    margin-left: 10px;
    width: 30px;  
    margin-top: 10px;
   }

   @media screen and (max-width: 1100px) {
      width: 280px !important;
      margin-bottom: 10px !important;
   }

   @media screen and (max-width: ${breakpoints.sm}px) {
    width: 90% !important;
    margin: auto;
    margin-bottom: 20px !important;

  }

`;

export const HeadingHighlight = styled.div`
  width: 100%;
  height: 30px;
  margin-top: 20px;

  @media screen and (max-width: ${breakpoints.md}px) {
    margin-top: -10px;

  }

  @media screen and (max-width: ${breakpoints.sm}px) {
    margin-top: 0px;

  }
`;


export const NewHighlight = styled.div`
  background-color: rgb(35, 214, 35);
  float:left;
  color:#fff;
  padding: 5px;
  border-radius: 5px;
  margin-left:15px;
  margin-top: 28px;
  font-size: 15px;
  @media screen and (max-width: ${breakpoints.sm}px) {
    margin-top: 25px;

  }

  @media screen and (max-width: 280px) {
    display: none;
  }
`;
